<template>
  <v-sheet color="transparent" @click="$emit('focus')" :style="getPadding()">
    <div class="d-flex align-center">
      <v-textarea
          v-model="value.text"
          :style="getStyle()"
          :placeholder="$t('EnterText')"
          class="pa-0 ma-0 heading text-center"
          background-color="transparent"
          style="text-align: center"
          row-height="1"
          ref="heading"
          hide-details
          single-line
          auto-grow
          dense
          flat
          solo
          :readonly="$store.state.newsletter.headingTypesArray.includes(value.type)"
      />
    </div>

  </v-sheet>
</template>

<script>
export default {
  name: "elementHeading",
  props : {
    value : {
      type : Object,
      default() { return {} }
    }
  },
  data () {
    return {
      headerStyle : null
    }
  },
  watch : {
    'value.color'(value) {
      this.headerStyle.style.color = value
    },
    'value.align'(value) {
      this.headerStyle.style.textAlign = value
    }
  },
  methods : {
    getPadding() {
      let style = '';
      if ( this.value.paddingTop ) {
        style += `padding-top: ${this.value.paddingTop}px;`
      }
      if ( this.value.paddingBottom ) {
        style += `padding-bottom: ${this.value.paddingBottom}px;`
      }
      if ( this.value.paddingLeft ) {
        style += `padding-left: ${this.value.paddingLeft}px;`
      }
      if ( this.value.paddingRight ) {
        style += `padding-right: ${this.value.paddingRight}px;`
      }
      return style
    },
    getStyle() {
      let style = '';



      switch (this.value.weight) {
        case 'light'  : style += 'font-weight: 300 ; '    ;  break;
        case 'medium' : style += 'font-weight: 500 ; '  ;  break;
        case 'bold'   : style += 'font-weight: 700 ; '    ;  break;
        default :  style +=      'font-weight: 500 ; '
      }

      switch (this.value.size) {
        case 'h1' : style += 'font-size: 2em    ;'    ;  break;
        case 'h2' : style += 'font-size: 1.5em  ;'  ;  break;
        case 'h3' : style += 'font-size: 1em    ;'    ;  break;
        case 'h4' : style += 'font-size: 0.92em ;' ;  break;
        case 'h5' : style += 'font-size: 0.83em ;' ;  break;
        case 'h6' : style += 'font-size: 0.67em ;' ;  break;
        default :  style += 'font-size: 2em ;'
      }

      style += 'line-height : 1; '

      return style
    },
    editElement(value) {
      this.$emit('select' , value)
    },
  },
  mounted() {
    let el = this.$refs.heading.$el
    this.headerStyle = el.querySelector('textarea');
    this.headerStyle.style.color = this.value.color
    this.headerStyle.style.textAlign = this.value.align
  }
}
</script>

<style scoped>
.v-textarea >>> .v-input__slot {
  border: 2px solid red;
  padding: 0 0 !important
}
</style>