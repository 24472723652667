<template>
  <div @click="$emit('focus')" :style="getPadding()">
    <div class="d-flex align-center">
      <v-textarea
          hide-details dense ref="heading" style="text-align: center; padding: -10px"
                   :style="getStyle()" class="pa-0 ma-0 heading text-center" v-model="value.text" background-color="transparent" @focus="editElement" flat solo single-line auto-grow row-height="1"  :placeholder="$t('EnterText')"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "elementText",
  props : {
    value : {
      type : Object,
      default() { return {} }
    }
  },
  data () {
    return {
      headerStyle : null
    }
  },
  watch : {
    'value.color'(value) {
      this.headerStyle.style.color = value
    },
    'value.align'(value) {
      this.headerStyle.style.textAlign = value
    }
  },
  methods : {
    getPadding() {
      let style = '';
      if ( this.value.paddingTop ) {
        style += `padding-top: ${this.value.paddingTop}px;`
      }
      if ( this.value.paddingBottom ) {
        style += `padding-bottom: ${this.value.paddingBottom}px;`
      }
      if ( this.value.paddingLeft ) {
        style += `padding-left: ${this.value.paddingLeft}px;`
      }
      if ( this.value.paddingRight ) {
        style += `padding-right: ${this.value.paddingRight}px;`
      }
      return style
    },
    getStyle() {
      let style = '';

      switch (this.value.weight) {
        case 'light'  : style += 'font-weight: 300 ; '    ;  break;
        case 'medium' : style += 'font-weight: 500 ; '  ;  break;
        case 'bold'   : style += 'font-weight: 700 ; '    ;  break;
        default :  style +=      'font-weight: 500 ; '
      }

      if (this.value.size) {
        style += `font-size : ${this.value.size}px !important;`
      } else {
        style += `font-size : 14px;`
      }

      style += 'line-height : 1; '

      return style
    },
    editElement() {
      this.$emit('select')
    },
  },
  mounted() {
    let el = this.$refs.heading.$el
    this.headerStyle = el.querySelector('textarea');
    this.headerStyle.style.color = this.value.color
    this.headerStyle.style.textAlign = this.value.align
  }
}
</script>

<style scoped>
.v-textarea >>> .v-input__slot {
  border: 2px solid red;
  padding: 0 !important
}
</style>