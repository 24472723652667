<template>
  <sub-page
      v-model="$store.state.newsletter.emailPageData"

      icon="mdi-chevron-left"
      :root="$t('Email')"
      :title="entity.name || ''"
      :subtitle="$t('Letter')"
      root-location="newsletter/email"
      :header-route="businessDashLink('newsletter/email')"
      :header-action="openSendNewsletter"
      :header-action-text="$t('Send')"
   >


    <template #default>

      <div class="fill-height" v-if="ready">
        <email-new
            v-if="!entity.is_published"
            :newsletter="entity"
            @update="entity = $event"
        />
        <email-overview
            v-if="entity.is_published"
            :entity="entity"
        />
      </div>

      <div v-else>
        <v-progress-circular indeterminate :color="wsACCENT"></v-progress-circular>
      </div>

    </template>

    <template #dialog>
      <wsDialog v-model="displaySendDialog"
                @save="sendNewsletter"
                :show-save="!emailSent"
                :save-text="$t('Confirm')"
                :cancel-text="$t('Close')"
                :title="$t('ConfirmNewsletterPublish')"
                width="700"
      >
        <v-fade-transition mode="out-in">
          <div key="confirmEmailSend" v-if="!emailSent">
            <v-img class="wsRounded mx-auto my-10 mb-15" width="330"    src="@/assets/img/newsletter/email_graphics.png" />

            <h2 class="mt-5 text-center font-weight-light">{{ $t('EverythingIsReady') }}! </h2>
            <h4 class="mb-10 text-center ">{{ $t('ConfirmNewsletterPublishMessage') }} </h4>
          </div>
          <div key="emailSent" v-else>
            <v-img class="wsRounded mx-auto my-10 mb-15" width="330"    src="@/assets/img/newsletter/email_sent.png" />
            <h4 class="mb-10 text-center ">{{ $t('NewsletterPublished') }} </h4>
          </div>
        </v-fade-transition>



      </wsDialog>
    </template>



  </sub-page>
</template>

<script>
import emailNew from "@/components/pages/businessDashboard/businessNewsletter/email/viewNewsletter/emailNew";
import emailOverview from "@/components/pages/businessDashboard/businessNewsletter/email/viewNewsletter/emailOverview";
import {mapActions} from "vuex";

export default {
  name: "viewNewsletter",
  components : {
    emailNew,
    emailOverview
  },
  props : {
    uuid : {
      type : String,
      default : ''
    },
    admin : {
      type : String
    }
  },
  data() {
    return {
      ready : false,
      navigation : 'design',
      entity : {
      },
      displaySendDialog : false,
      emailSent : false,
    }
  },
  computed : {
    readyToPublish() {
      return this.entity.newsletter_design && this.entity.total_receivers > 0
    }
  },
  watch : {
    readyToPublish(value) {
      this.$store.state.newsletter.emailReadyToPublish = value
    }
  },
  methods : {
    ...mapActions('newsletter', [
        'GET_NEWSLETTER',
        'EDIT_NEWSLETTER',
        'GET_NEWSLETTER_RECEIVERS_STATS',
        'PUBLISH_ADMIN_NEWSLETTER',
        'PUBLISH_NEWSLETTER'
    ]),

    openSendNewsletter() {
      this.displaySendDialog = true
    },
    async sendNewsletter() {

      let result = this.admin ?  await this.PUBLISH_ADMIN_NEWSLETTER(this.entity.uuid) : await this.PUBLISH_NEWSLETTER(this.entity.uuid)
      if ( !result ) {
        this.notify(this.$t(this.$store.state.ajax.error) , 'error')
        return
      }

      this.$emit('publish')

      this.notify(this.$t('NewsletterPublished'))
      this.emailSent = true
      this.initPage()

    },
    async openReceiversDetails() {
      let result = await this.GET_NEWSLETTER_RECEIVERS_STATS(this.uuid)
      if ( !result || result === true) { return }
      this.receivers = result
      this.displayReceivers = !this.displayReceivers

    },
    async initPage() {
      let result = await this.GET_NEWSLETTER(this.uuid)
      if ( !result || result === true) { return }
      this.entity = result
      this.displayDialog = false
      this.ready = true
      this.$store.state.newsletter.emailReadyToPublish = this.readyToPublish
    }

  },

  mounted() {
    this.initPage()
  },
  beforeDestroy() {
    this.$store.state.newsletter.emailReadyToPublish = false
  }

}
</script>

<style scoped>

</style>