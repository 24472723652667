<template>

  <div :style="getPadding">

    <v-sheet color="transparent"   v-if="value.src" >
      <v-img
          v-if="!value.width"
          :src="value.src"
          :style="`border-radius : ${value.borderRadius}px`"
          width="100%"
          height="100%"
      />
      <div v-else
           :class="`d-flex justify-${value.align}`"
      >
        <div>
          <v-img
              :src="value.src"
              :style="`border-radius : ${value.borderRadius}px`"
              :width="value.width"
              max-width="100%"
              height="100%"
              class="text-right "
          />
        </div>

      </div>
    </v-sheet>

    <v-sheet сolor="transparent"   v-else height="150">
      <ws-file-uploader
          @success="uploadImage"
          class="fill-height"
          disable-full-click
          public
      >

        <template #default="{selectFile}">
          <div class="d-flex fill-height justify-center">
            <div>
              <v-btn class="noCaps" :color="wsACCENT" text @click="selectFile()">
                <v-icon class="mr-2"  :color="wsATTENTION">mdi-file-search</v-icon>
                {{ $t('Choose') }}
              </v-btn>

              <ws-storage-file-picker
                  @click.stop=""
                  @input="value.src = $event.url"
                  image
                  text
              >
                <template #default="{select}">
                  <v-btn class="noCaps" :color="wsACCENT"  block text @click="select()">
                    <v-icon class="mr-2"  :color="wsATTENTION">mdi-file-search</v-icon>
                    {{ $t('ChooseFromStorage') }}
                  </v-btn>
                </template>
              </ws-storage-file-picker>

              <h5 :style="`color : ${wsACCENT}`" class="mt-2"> {{ $t('uploader.choose_image') }}</h5>



            </div>
          </div>
        </template>



      </ws-file-uploader>
    </v-sheet>


  </div>



</template>

<script>
import {mapActions} from "vuex";
export default {
  name: "elementImage",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    admin : {
      type : Boolean,
      default : false,
    }
  },
  data () {
    return {
      headerStyle : null
    }
  },
  computed : {
    getPadding() {
      let style = '';
      if ( this.value.paddingTop ) {
        style += `padding-top: ${this.value.paddingTop}px;`
      }
      if ( this.value.paddingBottom ) {
        style += `padding-bottom: ${this.value.paddingBottom}px;`
      }
      if ( this.value.paddingLeft ) {
        style += `padding-left: ${this.value.paddingLeft}px;`
      }
      if ( this.value.paddingRight ) {
        style += `padding-right: ${this.value.paddingRight}px;`
      }
      return style
    }
  },
  watch : {
    'value.color'(value) {
      this.headerStyle.style.color = value
    },
  },
  methods : {
    ...mapActions('templates',  ['UPLOAD_IMAGE' ]),
    ...mapActions('newsletter',  ['UPLOAD_ADMIN_IMAGE' ]),

    async uploadImage(file) {
      this.value.src = file.url
    },

    getMarginStyle() {
      let style = ''

      if ( this.value.marginTop ) {
        style += `padding-top: ${this.value.marginTop}px;`
      }
      if ( this.value.marginBottom ) {
        style += `padding-bottom: ${this.value.marginBottom}px;`
      }
      if ( this.value.marginLeft ) {
        style += `padding-left: ${this.value.marginLeft}px;`
      }
      if ( this.value.marginRight ) {
        style += `padding-right: ${this.value.marginRight}px;`
      }

      return style

    },

    getBorderRadiusStyle() {
      let style = '';

      if ( this.value.borderRadius ) {
        style += `border-radius : ${this.value.borderRadius}px !important; `
      }

      return style
    },

    getButtonStyle() {
      let style = '';

      if ( this.value.borderRadius ) {
        style += `border-radius : ${this.value.borderRadius}px !important; `
      }

      if ( this.value.width ) {
        style += `width :  ${this.value.width}px; `
      } else {
        style += `width :  100%; `
      }

      // if ( this.value.height ) {
      //   style += `height :  ${this.value.height}px; `
      // } else {
      //   style += `height :  100%; `
      // }

      if ( this.value.paddingTop ) {
        style += `padding-top: ${this.value.paddingTop}px;`
      }
      if ( this.value.paddingBottom ) {
        style += `padding-bottom: ${this.value.paddingBottom}px;`
      }
      if ( this.value.paddingLeft ) {
        style += `padding-left: ${this.value.paddingLeft}px;`
      }
      if ( this.value.paddingRight ) {
        style += `padding-right: ${this.value.paddingRight}px;`
      }

      return style
    },
    getAlignClass() {
      switch (this.value.align) {
        case 'left'    : return ''
        case 'center'  : return 'justify-center'
        case 'right'   : return 'justify-end'
        default : return ''
      }
    },
    getStyle() {
      let style = '';

      switch (this.value.weight) {
        case 'light'  : style += 'font-weight: 300 ; '    ;  break;
        case 'medium' : style += 'font-weight: 500 ; '  ;  break;
        case 'bold'   : style += 'font-weight: 700 ; '    ;  break;
        default :  style +=      'font-weight: 500 ; '
      }

      switch (this.value.size) {
        case 'h1' : style += 'font-size: 2em    ;'    ;  break;
        case 'h2' : style += 'font-size: 1.5em  ;'  ;  break;
        case 'h3' : style += 'font-size: 1em    ;'    ;  break;
        case 'h4' : style += 'font-size: 0.92em ;' ;  break;
        case 'h5' : style += 'font-size: 0.83em ;' ;  break;
        case 'h6' : style += 'font-size: 0.67em ;' ;  break;
        default :  style += 'font-size: 2em ;'
      }

      style += 'line-height : 1; '

      return style
    },
    editElement() {
      this.$emit('select')
    },
  },
  mounted() {
    let el = this.$refs.heading.$el
    this.headerStyle = el.querySelector('textarea');
    this.headerStyle.style.color = this.value.color
    this.headerStyle.style.textAlign = 'center'
  }
}
</script>

<style scoped>

</style>