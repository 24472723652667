var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.deselect),expression:"deselect"}],staticStyle:{"position":"relative"}},[_c('v-expand-x-transition',[(_vm.displaySettings)?_c('element-edit-dialog',{attrs:{"element":_vm.value},on:{"close":function($event){_vm.displaySettings = false},"update":_vm.updateElement},model:{value:(_vm.displaySettings),callback:function ($$v) {_vm.displaySettings=$$v},expression:"displaySettings"}}):_vm._e()],1),(_vm.displaySettingsCondition(hover))?_c('v-sheet',{staticClass:"  d-flex  pb-1",class:[{'mt-n10' : true}],staticStyle:{"position":"absolute"},attrs:{"color":"transparent"}},[_c('v-sheet',{staticClass:"wsRoundedLight d-flex align-center",staticStyle:{"z-index":"250"},style:(("border : 1px solid " + _vm.wsBACKGROUND))},[_c('v-icon',{staticClass:"element_handle",staticStyle:{"cursor":"grab"},on:{"click":function($event){_vm.edit = false}}},[_vm._v("mdi-drag")]),(_vm.buttonCondition || _vm.value.type === 'text' )?[_c('ft-select',{attrs:{"items":_vm.sizeSelect},on:{"expand":function($event){_vm.edit = $event}},model:{value:(_vm.value.size),callback:function ($$v) {_vm.$set(_vm.value, "size", $$v)},expression:"value.size"}},[_c('v-btn',{attrs:{"color":_vm.wsACCENT,"icon":""}},[(_vm.headingCondition || _vm.value.type === 'button')?_c('v-icon',[_vm._v(_vm._s(_vm.sizeIcon))]):_c('h5',[_vm._v(" "+_vm._s(_vm.value.size))])],1)],1),_c('ft-select',{attrs:{"items":_vm.weightSelect},on:{"expand":function($event){_vm.edit = $event}},model:{value:(_vm.value.weight),callback:function ($$v) {_vm.$set(_vm.value, "weight", $$v)},expression:"value.weight"}},[_c('v-btn',{attrs:{"color":_vm.wsACCENT,"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.weightIcon))])],1)],1),_c('v-menu',{key:"color_picker_menu",attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 mx-0",attrs:{"color":_vm.value.color,"text":"","min-width":"35","width":"35"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-text-variant-outline")])],1)]}}],null,true),model:{value:(_vm.backgroundDropdown),callback:function ($$v) {_vm.backgroundDropdown=$$v},expression:"backgroundDropdown"}},[_c('v-sheet',{staticClass:"pa-2 salvation-toolbar"},[_c('a-color-picker',{key:"color_picker",attrs:{"color-set":_vm.$store.state.newsletter.colorSet},model:{value:(_vm.value.color),callback:function ($$v) {_vm.$set(_vm.value, "color", $$v)},expression:"value.color"}})],1)],1),_c('v-menu',{key:"background_color_picker_menu",attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 mx-0",attrs:{"color":_vm.value.background,"text":"","min-width":"35","width":"35"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-circle")])],1)]}}],null,true),model:{value:(_vm.colorDropdown),callback:function ($$v) {_vm.colorDropdown=$$v},expression:"colorDropdown"}},[_c('v-sheet',{staticClass:"pa-2 salvation-toolbar"},[_c('a-color-picker',{key:"background_color_picker",attrs:{"color-set":_vm.$store.state.newsletter.colorSet},model:{value:(_vm.value.background),callback:function ($$v) {_vm.$set(_vm.value, "background", $$v)},expression:"value.background"}})],1)],1),_c('v-divider',{style:(("border-color : " + _vm.wsBACKGROUND)),attrs:{"vertical":""}})]:_vm._e(),(_vm.headingCondition)?[_c('ft-select',{attrs:{"items":_vm.sizeSelect},on:{"expand":function($event){_vm.edit = $event}},model:{value:(_vm.value.size),callback:function ($$v) {_vm.$set(_vm.value, "size", $$v)},expression:"value.size"}},[_c('v-btn',{attrs:{"color":_vm.wsACCENT,"icon":""}},[(_vm.headingCondition)?_c('v-icon',[_vm._v(_vm._s(_vm.sizeIcon))]):_c('h5',[_vm._v(" "+_vm._s(_vm.value.size))])],1)],1),_c('ft-select',{attrs:{"items":_vm.weightSelect},on:{"expand":function($event){_vm.edit = $event}},model:{value:(_vm.value.weight),callback:function ($$v) {_vm.$set(_vm.value, "weight", $$v)},expression:"value.weight"}},[_c('v-btn',{attrs:{"color":_vm.wsACCENT,"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.weightIcon))])],1)],1),_c('ft-select',{attrs:{"items":_vm.alignSelect},on:{"expand":function($event){_vm.edit = $event}},model:{value:(_vm.value.align),callback:function ($$v) {_vm.$set(_vm.value, "align", $$v)},expression:"value.align"}},[_c('v-btn',{attrs:{"color":_vm.wsACCENT,"icon":""}},[_c('v-icon',[_vm._v("mdi-format-align-"+_vm._s(_vm.value.align || 'left'))])],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 mx-0",attrs:{"color":_vm.value.color,"text":"","min-width":"35","width":"35"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-circle")])],1)]}}],null,true),model:{value:(_vm.colorDropdown),callback:function ($$v) {_vm.colorDropdown=$$v},expression:"colorDropdown"}},[_c('v-sheet',{staticClass:"pa-2 salvation-toolbar"},[_c('a-color-picker',{attrs:{"color-set":_vm.$store.state.newsletter.colorSet},model:{value:(_vm.value.color),callback:function ($$v) {_vm.$set(_vm.value, "color", $$v)},expression:"value.color"}})],1)],1),_c('v-divider',{style:(("border-color : " + _vm.wsBACKGROUND)),attrs:{"vertical":""}})]:_vm._e(),_c('v-btn',{attrs:{"color":_vm.wsACCENT,"icon":""},on:{"click":function($event){_vm.displaySettings = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"color":_vm.wsACCENT,"icon":""},on:{"click":function($event){return _vm.$emit('copy')}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{attrs:{"color":_vm.wsACCENT,"icon":""},on:{"click":function($event){return _vm.$emit('delete')}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],2)],1):_vm._e(),_c('v-sheet',{attrs:{"color":"transparent"}},[(_vm.$store.state.newsletter.headingTypesArray.includes(_vm.value.type) || _vm.value.type === 'heading' )?_c('element-heading',{attrs:{"value":_vm.value},on:{"focus":function($event){_vm.edit = true}}}):_vm._e(),(_vm.value.type ===  'text'  )?_c('element-text',{attrs:{"value":_vm.value},on:{"focus":function($event){_vm.edit = true}}}):_vm._e(),(_vm.value.type ===  'image'  )?_c('element-image',{attrs:{"admin":_vm.admin,"value":_vm.value},on:{"click":function($event){$event.stopPropagation();}}}):_vm._e(),(_vm.$store.state.newsletter.buttonTypesArray.includes(_vm.value.type) || _vm.value.type === 'button' )?_c('element-button',{attrs:{"value":_vm.value},on:{"click":function($event){$event.stopPropagation();}}}):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }