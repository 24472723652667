<template>
  <v-dialog
      v-model="display"
      overlay-color="transparent"
      style="z-index: 500 !important;"
      transition="slide"
      :fullscreen="SM"
      width="400">
    <v-sheet
        elevation="3"
        class="element-style pa-4 pb-16 overflow-y-auto"
        height="100vh"
        style="z-index : 99999999999999 !important; "
        :width="!SM ? 400 : '100%'"
    >

      <div class="d-flex justify-space-between align-center mb-5">
        <h4><v-icon :color="wsACCENT" class="mr-2">mdi-pencil</v-icon>{{  $t('EditElement')  }}</h4>
        <v-btn icon @click="display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>


      <ws-accordion :items="elementSections">
        <template #item.button>
          <div class="pa-4">
            <ws-text-field
                class="mt-3"
                :label="$t('Link')"
                placeholder="https://westudy.ua"
                v-model="element.url"
                clearable
                @change="validateUrl"
                :error="linkError"
                @input="linkError = false"
            />

            <a-parameter-slider
                v-model="element.borderRadius"
                :label="$t('Rounded')"
                class="mt-4"
                min="0"
                max="300"
            />

            <a-parameter-slider
                v-model="element.width"
                :label="$t('Width')"
                icon="mdi-arrow-left-right"
                class="mt-4"
                default="350"
                min="10"
                max="700"
            />


          </div>
        </template>
        <template #item.image>
          <div class="pa-4">
            <v-sheet  v-if="!element.src" height="240">
              <ws-file-uploader
                  @success="updateImage"
                  public
              />
              <ws-storage-file-picker
                  @input="updateImage"
                  image
                  text
                  class="mt-2 mx-auto"
                  block
              />
            </v-sheet>
            <div v-else>
              <v-img
                  :src="element.src"
                  class="wsRoundedLight"
                  aspect-ratio="1.5"
              />
              <div class="d-flex justify-end pt-2">
                <v-btn @click="element.src = null" :color="wsACCENT" text class="noCaps">
                  <v-icon>mdi-delete</v-icon>
                  {{ $t('Delete') }}
                </v-btn>
              </div>
              <ws-text-field
                  class="mt-3"
                  :label="$t('Link')"
                  placeholder="https://westudy.ua"
                  v-model="element.url"
                  clearable
                  @change="validateUrl"
                  :error="linkError"
                  @input="linkError = false"
              />

              <a-parameter-switch
                  class="mt-5"
                  @input="updateImageContain"
                  v-model="fullImage"
                  label="avalon.image.contain"
              />

              <div v-if="!fullImage">
                <h5 class="my-5">{{ $t('Align') }}</h5>
                <ws-select
                    v-model="element.align"
                    :items="alignSelect"
                    outlined dense hide-details
                    :placeholder="$t('Align')"
                />

                <a-parameter-slider
                    v-model="element.width"
                    :label="$t('Width')"
                    icon="mdi-arrow-split-vertical"
                    class="mt-4"
                    default="350"
                    min="10"
                    max="700"
                />
              </div>

              <a-parameter-slider
                  v-model="element.borderRadius"
                  :label="$t('Rounded')"
                  class="mt-4"
                  min="0"
                  max="300"
              />



            </div>




          </div>
        </template>
        <template #item.margins>

          <div class="pa-4">
            <h4 class="mb-4">{{ $t('Paddings') }}</h4>
            <a-parameter-slider
                @input="updatePaddingX"
                v-model="paddingX"
                :label="$t('Horizontal')"
                icon="mdi-arrow-collapse-vertical"
                class="mt-4"
                min="0"
                max="100"
            />
            <a-parameter-slider
                @input="updatePaddingY"
                v-model="paddingY"
                :label="$t('Vertical')"
                icon="mdi-arrow-collapse-horizontal"
                class="mt-4"
                min="0"
                max="100"
            />
          </div>


        </template>
      </ws-accordion>


    </v-sheet>
  </v-dialog>

</template>

<script>

export default {
  name: "avalonBlocSettings",
  components : {
  },
  props : {
    value : {
      type : Boolean,
      default : false,
    },
    element : {
      type : Object,
      default() { return {} }
    },
    maxHeight : {
      type : Number,
      default : 0,
    },
    maxHeightSM : {
      type : Number,
      default : 0,
    }
  },
  data() {
    return {
      linkError : false,
      fullImage : true,
      paddingX : 0,
      paddingY : 0,
      marginX : 0,
      marginY : 0,
      display : false,
      preventClose : false,
      columnsSelect : [
        { text : 1 , value : 1 },
        { text : 2 , value : 2 },
        { text : 3 , value : 3 }
      ]
    }
  },
  computed : {
    alignSelect() {
      return [
        { text : this.$t('AlignLeft')   , value : 'start' },
        { text : this.$t('AlignCenter') , value : 'center' },
        { text : this.$t('AlignRight')  , value : 'end' },
      ]
    },
    elementSections() {
      let items =  [
        { name : this.$t('format.title') , value : 'margins' , icon : 'mdi-arrow-all', expanded : true },
      ]

      if ( this.element.type === 'image') {
        items.unshift( { name : this.$t('Image') , value : 'image' , icon : 'mdi-image', expanded : true },)
      }

      if ( this.element.type === 'button') {
        items.unshift( { name : this.$t('Button') , value : 'button' , icon : 'mdi-button-pointer', expanded : true },)
      }

      return items
    },
    backgroundTypes() {
      return [
        { text : this.$t('Color') , value : 'color' , icon : 'mdi-palette'},
        { text : this.$t('Image') , value : 'image' , icon : 'mdi-image'},
      ]
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input',this.display)
      }
    }
  },
  methods : {
    validateUrl($event) {
      if ( !$event ) {
        return true
      }

      let regex = "https?://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]"
      const urlPattern = new RegExp(regex);

      if ( !urlPattern.test($event) ) {
        this.linkError = true
        this.notify(this.$t('PleaseEnterValidLink') , 'warning')
        return false
      }

      return true

    },

    updateImageContain(value) {
      if ( value ) {
        this.element.width = null
        this.element.align = null
      }
    },
    updatePaddingX(value) {
      this.element.paddingLeft = value
      this.element.paddingRight = value
    //  this.updateElement()
    },
    updatePaddingY(value) {
      this.element.paddingTop = value
      this.element.paddingBottom = value
   //   this.updateElement()
    },
    updateMarginX(value) {
      this.element.marginLeft = value
      this.element.marginRight = value
    //  this.updateElement()
    },
    updateMarginY(value) {
      this.element.marginTop = value
      this.element.marginBottom = value
    //  this.updateElement()
    },
    updateImage(file) {
      this.element.src = file.url
  //    this.updateElement()
    },
    async updateElement() {
      this.element = JSON.parse(JSON.stringify(this.element))
      this.$emit('update',this.element)
    }
  },
  mounted() {
    this.display = this.value

    if ( this.element.width) {
      this.fullImage = false
    }


    if ( this.element.paddingLeft === this.element.paddingRight ) {
      this.paddingX = this.element.paddingLeft
    }

    if ( this.element.paddingTop === this.element.paddingBottom ) {
      this.paddingY = this.element.paddingTop
    }

    if ( this.element.marginLeft === this.element.marginRight ) {
      this.marginX = this.element.marginLeft
    }

    if ( this.element.marginTop === this.element.marginBottom ) {
      this.marginY = this.element.marginTop
    }

  },
  beforeDestroy() {
    if ( this.element.url ) {
      if (!this.validateUrl(this.element.url) ) {
        this.element.url = null
      }
    }
  }
}
</script>

<style scoped>
.element-style {
  position:fixed;
  outline: none;
  top:0;bottom: 0;
  right: 0;
  height: 100% ;
  z-index:1111
}
</style>