<template>
  <v-hover #default="{hover}">
  <div :style="getMarginStyle()" >

    <!-- Block Settings Sidebar  -->
    <v-expand-x-transition>
      <block-edit-dialog
          @close="displaySettings = false "
          v-if="displaySettings"
          v-model="displaySettings"
          :block="value"
      />
    </v-expand-x-transition>
    <!-- Block Edit Panel  -->
    <v-sheet
        color="transparent"
        v-if="hover || edit"
        class="  d-flex justify-end pb-1"
        :class="[{'mt-n10' : true}]"
        style="position:absolute ; width: 100%;"
    >
      <v-sheet
          :style="`border : 1px solid ${wsBACKGROUND}`"
          class="wsRoundedLight d-flex align-center"
          style="z-index: 250"
      >
        <v-btn @click="displaySettings = true" :color="wsACCENT" icon >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn @click="$emit('copy')" :color="wsACCENT" icon >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <v-btn @click="$emit('delete')"  :color="wsACCENT" icon >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-divider  :style="`border-color : ${wsBACKGROUND}`" vertical/>
        <v-btn v-if="index >0 " @click="$emit('move',-1)"  :color="wsACCENT" icon >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
        <v-btn v-if="!isLast"  @click="$emit('move',1)" :color="wsACCENT" icon >
          <v-icon>mdi-arrow-down</v-icon>
        </v-btn>
      </v-sheet>
    </v-sheet>
    <!-- Block Content  -->
    <v-sheet :color="value.backgroundColor"  :dark="value.dark"
               :style="getColumnStyle(hover)"
               @click="selectBlock" >
          <v-row no-gutters class="ma-0 pa-0">
            <!-- Block Columns  -->
            <v-col
                v-for="(column,i) in value.elements"
                :key="i"
                style="position: relative"
            >
              <!-- Add Item Bottom  -->
              <v-sheet
                  width="100%"
                  v-if="(hover || edit) && column.length > 0"
                  color="transparent"
                  class="d-flex justify-center"
                  :style="`position: absolute;  z-index: 1; top : ${ (-18) - value.paddingTop }px;`"
              >
                <v-sheet style="border-radius : 50%">
                  <ft-select
                      @input="addAction($event , i , 0 )"
                      @expand="edit = $event"
                      :items="[{ text : $t('Block'), icon : 'mdi-page-layout-body' , value : 'block' }]"
                      :icon-color="wsATTENTION"
                      open-on-hover
                  >
                    <v-btn icon :dark="value.dark" class="noCaps" :color="wsATTENTION">
                      <v-icon large >mdi-plus-circle</v-icon>
                    </v-btn>
                  </ft-select>

                </v-sheet>
              </v-sheet>
              <!-- Column Content  -->
              <template v-if="column.length > 0 ">
                  <draggable v-model="value.elements[i]"
                             :empty-insert-threshold="30"
                             :forceFallback="true"
                             group="elements"
                             @start="drag = true"
                             @end=" drag = false"
                             v-bind="opt"
                             handle=".element_handle"
                             tag="div">

                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">

                    <template v-for="(element,j) in column" >
                      <editor-element
                          :disableEdit="drag"
                          @delete="$emit('deleteElement' , {column : i,index : j})"
                          @copy="$emit('copyElement' ,  {element : element , column : i,position : j + 1  })"
                          :value="element"
                          :index="j"
                          :key="j"
                          :is-last="j === column.length -1"
                          :admin="admin"
                      />
                    </template>
                    </transition-group>

                  </draggable>
                </template>
              <!-- Empty Column buttons  -->
              <v-sheet v-else color="transparent"  class="d-flex align-center justify-center">
                <div v-if="value.elements.length < 2" class="d-flex justify-center">
                  <v-sheet
                      :style="`border-color: ${wsACCENT} `"
                      class=" wsRoundedLight "
                      :class="{'d-flex' : !MOBILE_VIEW}"
                      outlined
                  >
                    <template v-for="(item,k) in blockItemsSelect">
                      <v-btn :color="wsACCENT"
                             @click="addElement(item.value , i)"
                             style="border-radius: unset !important"
                             class="noCaps"
                             text
                             :block="MOBILE_VIEW"
                             :key="'addElement' + i + k + j"
                      >
                        <h5 style="font-size: 13px ; font-weight: 600">
                          {{ item.text }}
                        </h5>
                      </v-btn>

                      <v-divider
                          v-if="k < blockItemsSelect.length - 1"
                          :key="'addElement_divider' + i + k + j"
                          :style="`border-color: ${wsACCENT} `"
                          :vertical="!SM"

                      />
                    </template>
                  </v-sheet>
                </div>
                <ft-select v-else
                    @input="addAction($event , i , 1)"
                    @expand="edit = $event"
                    :items="blockItemsSelect"
                    :icon-color="wsATTENTION"
                    open-on-hover
                >
                  <v-btn outlined :dark="value.dark" class="noCaps" :color="wsACCENT">
                    {{ $t('AddElement') }}
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </ft-select>
              </v-sheet>
              <!-- Add Item Bottom  -->
              <v-sheet
                  width="100%"
                  v-if="(hover || edit) && column.length > 0"
                  color="transparent"
                  class="d-flex justify-center"
                  :style="`position: absolute;  z-index: 1; bottom : ${ (-18) - value.paddingBottom }px;`"
              >
                <v-sheet style="border-radius : 50%">
                  <ft-select
                      @input="addAction($event , i , 1)"
                      @expand="edit = $event"
                      :items="blockItemsSelectFull"
                      :icon-color="wsATTENTION"
                      open-on-hover
                  >
                    <v-btn icon :dark="value.dark" class="noCaps" :color="wsATTENTION">
                      <v-icon large >mdi-plus-circle</v-icon>
                    </v-btn>
                  </ft-select>

                </v-sheet>
              </v-sheet>

            </v-col>
          </v-row>
      </v-sheet>


  </div>
  </v-hover>
</template>

<script>

import editorElement
  from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/editor/editorElement";
import blockEditDialog
  from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/dialogs/blockEditDialog";
import draggable from "vuedraggable";

export default {
  name: "blockRow",
  components : {
    editorElement,
    blockEditDialog,
    draggable,
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    admin : {
      type : Boolean,
      default : false,
    },
    index : {
      type : Number
    },
    isLast : {
      type : Boolean,
      default : false
    },
    systemEmail : {}
  },
  data() {
    return {
      drag : false,
      selectedElement : {} ,
      displayConfirmDelete : false,
      displaySettings : false,
      edit : false,
      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
    }
  },
  computed : {
    blockItemsSelect() {
      return [
        { text : this.$t('ElementHeading')   ,  icon : 'mdi-format-header-1'    ,   value : 'heading' },
        { text : this.$t('ElementText')      ,  icon : 'mdi-text'    ,   value : 'text' },
        { text : this.$t('ElementImage')     ,  icon : 'mdi-image'    ,   value : 'image' },
        { text : this.$t('ElementButton')    ,  icon : 'mdi-gesture-tap-button'    ,   value : 'button' },

      ]
    },
    blockItemsSelectFull() {
      let items = JSON.parse(JSON.stringify(this.blockItemsSelect))
      items.push(  { text : this.$t('ClientPanel')  ,  icon : 'mdi-view-dashboard'    ,   value : 'dashboard_link' })
      items.unshift(   { text : this.$t('Block') ,  icon : 'mdi-page-layout-body'    ,   value : 'block' })
      if ( this.systemEmail === 'user_registration_welcome') {
        items.push(  {  text : this.$t('UserAuthData') , icon : 'mdi-account' ,  value : 'user_auth_data'  } )
      }
      if ( this.systemEmail === 'course_registration_welcome') {
        items.push(  {  text : this.$t('CourseName') , icon : 'mdi-school-outline' ,  value : 'order_course_name'  } )
      }
      if ( this.systemEmail === 'password_change_confirmation') {
        items.push(  {  text : this.$t('PasswordChangeCode') , icon : 'mdi-form-textbox-password' ,  value : 'password_reset_code'  } )
      }
      if ( this.systemEmail === 'course_new_order') {
        items.push(  {  text : this.$t('OrderNumber') , icon : 'mdi-numeric' ,  value : 'order_number'  } )
        items.push(  {  text : this.$t('CourseName') , icon : 'mdi-school-outline' ,  value : 'order_course_name'  } )
        items.push(  {  text : this.$t('OrderPrice') , icon : 'mdi-cash-multiple' ,  value : 'order_price'  } )
        if (this.HAS_ONLINE_PAYMENT) {
          items.push(  {  text : this.$t('PaymentButton') , icon : 'mdi-credit-card-outline' ,  value : 'order_payment_button'  } )
        }

      }


      return items
    },
    defaultElements() {
      return {
        // default items
        heading : {
          type : 'heading',
          text : '',
          size : 'h1',
          weight : 'medium',
          column : 1,
          color : '#000000',
          align : 'left',
          uuid : '',
          paddingTop : 10,
          paddingBottom : 10,
          paddingLeft : 10,
          paddingRight : 10,
          marginTop : 0,
          marginBottom : 0,
          marginLeft : 0,
          marginRight : 0,
        },
        text : {
          type : 'text',
          text : '',
          size : 14,
          weight : 'light',
          column : 1,
          color : '#000000',
          align : 'left',
          uuid : '',
          paddingTop : 10,
          paddingBottom : 10,
          paddingLeft : 10,
          paddingRight : 10,
          marginTop : 0,
          marginBottom : 0,
          marginLeft : 0,
          marginRight : 0,
        },
        button : {
          type : 'button',
          text : '',
          size : 'h3',
          weight : 'medium',
          column : 1,
          color : this.$store.state.newsletter.colorSet[1],
          background : this.$store.state.newsletter.colorSet[5],
          align     : 'center',
          uuid : '',
          url : '',
          borderRadius : 15,
          width : 250,
          paddingTop : 10,
          paddingBottom : 10,
          paddingLeft : 10,
          paddingRight : 10,
          marginTop : 0,
          marginBottom : 0,
          marginLeft : 0,
          marginRight : 0,
        },
        image : {
          type : 'image',
          src : '',
          column : 1,
          color : '#ffffff',
          background : this.wsATTENTION,
          align     : 'center',
          uuid : '',
          url : '',
          borderRadius : 15,
          width : null,
          height : null,
          position : 'center',
          paddingTop : 10,
          paddingBottom : 10,
          paddingLeft : 10,
          paddingRight : 10,
          marginTop : 0,
          marginBottom : 0,
          marginLeft : 0,
          marginRight : 0,
        },
        // dynamic items
        user_auth_data : {
          source : 'heading',
          type : 'user_auth_data',
          text : this.$t('UserLogin') + ': ' + this.$t('UserEmail') + "\n" + this.$t('Password') + ': ' + this.$t('UserPassword') ,
        },
        course_name : {
          source : 'heading',
          type : 'course_name',
          text : this.$t('CourseName'),
        },
        password_reset_code : {
          source : 'heading',
          type : 'password_reset_code',
          text : this.$t('PasswordChangeCode'),
        },
        // order
        order_number : {
          source : 'heading',
          type : 'order_number',
          text : this.$t('OrderNumber')
        },
        order_course_name : {
          source : 'heading',
          type : 'order_course_name',
          text : this.$t('CourseName')
        },
        order_price : {
          source : 'heading',
          type : 'order_price',
          text : this.$t('OrderPrice')
        },
        order_payment_button : {
          source : 'button',
          type : 'order_payment_button',
          text : this.$t('PaymentButton'),
        },
        dashboard_link : {
          source : 'button',
          type : 'dashboard_link',
          text : this.$t('GoToDashboard'),
        },
      }
    }
  },
  watch : {
    'value.rows'(value) {
      if ( value === 1 ) {
        if ( this.value.elements[1]) {
          this.value.elements[1].forEach(element =>{
            this.value.elements[0].push(element)
          })
        }
        if ( this.value.elements[2]) {
          this.value.elements[2].forEach(element =>{
            this.value.elements[0].push(element)
          })
        }
        this.value.elements.splice(1,2)
      }
      if ( value === 2 ) {
        if ( !this.value.elements[1]) {
          this.value.elements.push([])
        }
        if ( this.value.elements[2]) {
          this.value.elements[2].forEach(element =>{
            this.value.elements[1].push(element)
          })
          this.value.elements.splice(2,1)
        }
      }

      if ( value === 3 ) {
        if ( !this.value.elements[1]) {
          this.value.elements.push([])
        }
        if ( !this.value.elements[2]) {
          this.value.elements.push([])
        }
      }


    }
  },
  methods : {
    addAction(item, index , direction) {
      if ( item === 'block' ) {
        this.$emit('addBlock',direction)
        return
      }
      this.addElement(item , index)
    },
    selectElement(element,i,j) {
      let data = {
        element : element,
        column : i,
        index : j
      }
      this.$emit('selectElement',data)
    },
    selectBlock() {
      this.$emit('selectBlock')
    },
    addElement( type , column = 1) {
      let element = {}
      if ( this.defaultElements[type] ) {
        if ( !this.defaultElements[type].source  ) {
          element = JSON.parse(JSON.stringify(this.defaultElements[type]))
        } else {
          element = JSON.parse(JSON.stringify(this.defaultElements[this.defaultElements[type].source]))
          Object.keys(this.defaultElements[type]).forEach(key => {
            if ( key !== 'source') {
              element[key] = this.defaultElements[type][key]
            }
          })
        }
      }
      element.column = column
      element.uuid = this.$uuid.v4()
      this.value.elements[column].push(element)
    },
    getElements(column) {
      let items = this.value.elements;
      if ( this.value.rows === 2 ) {

        items = items.filter( el=> column === 1 ? (el.column === column) :   [2,3].includes(el.column)  )
      }
      if ( this.value.rows === 3 ) {
        items = items.filter( el=> el.column === column )
      }
      return items;
    },
    getColumnStyle(hover) {
      let style = '';

      if (hover ) {
        style += `outline : 2px solid ${this.wsATTENTION}; outline-offset: -2px;`
      }
      if ( this.value.borderRadius ) {
        style += `border-radius: ${this.value.borderRadius}px;`
      }

      if ( this.value.paddingTop ) {
        style += `padding-top: ${this.value.paddingTop}px;`
      }
      if ( this.value.paddingBottom ) {
        style += `padding-bottom: ${this.value.paddingBottom}px;`
      }
      if ( this.value.paddingLeft ) {
        style += `padding-left: ${this.value.paddingLeft}px;`
      }
      if ( this.value.paddingRight ) {
        style += `padding-right: ${this.value.paddingRight}px;`
      }

      return style
    },
    getMarginStyle() {
      let style = 'position : relative;';


      if ( this.value.marginTop ) {
        style += `padding-top: ${this.value.marginTop || 0}px;`
      }
      if ( this.value.marginBottom ) {
        style += `padding-bottom: ${this.value.marginBottom|| 0}px;`
      }
      if ( this.value.marginLeft ) {
        style += `padding-left: ${this.value.marginLeft|| 0}px;`
      }
      if ( this.value.marginRight ) {
        style += `padding-right: ${this.value.marginRight|| 0}px;`
      }



      return style
    },
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>