<template>
  <v-dialog
      v-model="display"
      overlay-color="transparent"
      style="z-index: 500 !important;"
      transition="slide"
      width="400">
    <v-sheet
        elevation="3"
        class="block-style pa-4 pb-16 overflow-y-auto"
        height="100vh"
        style="z-index : 99999999999999 !important; "
        :width="!SM ? 400 : '100%'"
    >

      <div class="d-flex justify-space-between align-center mb-5">
        <h4><v-icon :color="wsACCENT" class="mr-2">mdi-pencil</v-icon>{{  $t('Settings')  }}</h4>
        <v-btn icon @click="display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <ws-accordion :items="blockSections">
        <template #item.general>
          <div class="pa-4">
            <h5 class="">{{ $t('Background') }}</h5>
            <a-color-picker
                width="100%"
                @input="updateBlock"
                v-model="block.backgroundColor"
                class="mt-2"
                :color-set="$store.state.newsletter.colorSet"
            />
          </div>
        </template>
        <template #item.contacts>

          <div class="pa-4">


            <ws-phone-input v-model="block.phone" :label="$t('Phone')"> </ws-phone-input>
            <ws-text-field v-model="block.email" outlined dense hide-details :label="$t('Email')"> </ws-text-field>
            <ws-text-field area height="65" class="mt-6" v-model="block.address" outlined dense hide-details :label="$t('Address')" />

          </div>


        </template>
      </ws-accordion>


    </v-sheet>
  </v-dialog>

</template>

<script>

export default {
  name: "avalonBlocSettings",
  components : {
  },
  props : {
    value : {
      type : Boolean,
      default : false,
    },
    block : {
      type : Object,
      default() { return {} }
    },
    maxHeight : {
      type : Number,
      default : 0,
    },
    maxHeightSM : {
      type : Number,
      default : 0,
    }
  },
  data() {
    return {
      paddingX : 0,
      paddingY : 0,
      marginX : 0,
      marginY : 0,
      display : false,
      preventClose : false,
      columnsSelect : [
        { text : 1 , value : 1 },
        { text : 2 , value : 2 },
        { text : 3 , value : 3 }
      ]
    }
  },
  computed : {

    blockSections() {
      return [
        { name : this.$t('Design')   , value : 'general' , icon : 'mdi-palette', expanded : true },
        { name : this.$t('Contacts') , value : 'contacts' , icon : 'mdi-email', expanded : true },
      ]
    },
    backgroundTypes() {
      return [
        { text : this.$t('Color') , value : 'color' , icon : 'mdi-palette'},
        { text : this.$t('Image') , value : 'image' , icon : 'mdi-image'},
        // { text : this.$t('Video') , value : 'video' , icon : 'mdi-video'},
        // { text : this.$t('None')  ,  value : null   , icon : 'mdi-cancel'}
      ]
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input',this.display)
      }
    }
  },
  methods : {
    updateImage($event) {
      this.block.config.image = $event.url
      this.updateBlock()
    },
    async updateBlock() {
      this.block = JSON.parse(JSON.stringify(this.block))
      this.$emit('update',this.block)
    }
  },
  mounted() {
    this.display = this.value

    if ( this.block.paddingLeft === this.block.paddingRight ) {
      this.paddingX = this.block.paddingLeft
    }

    if ( this.block.paddingTop === this.block.paddingBottom ) {
      this.paddingY = this.block.paddingTop
    }

    if ( this.block.marginLeft === this.block.marginRight ) {
      this.marginX = this.block.marginLeft
    }

    if ( this.block.marginTop === this.block.marginBottom ) {
      this.marginY = this.block.marginTop
    }

  }
}
</script>

<style scoped>
.block-style {
  position:fixed;
  outline: none;
  top:0;bottom: 0;
  right: 0;
  height: 100% ;
  z-index:1111
}
</style>