<template>

  <div class="d-flex" :class="getAlignClass()">
    <div :style="getButtonStyle()">
      <v-textarea
          v-model="value.text"
          @focus="editElement" flat solo single-line auto-grow row-height="1"
          :placeholder="$t('EnterText')"
          :readonly="$store.state.newsletter.buttonTypesArray.includes(value.type)"
          :style="getStyle()"
          class="pa-0 ma-0 heading text-center"
          background-color="transparent"
          style="text-align: center"
          ref="heading"
          hide-details
          dense
      />
    </div>
  </div>

</template>

<script>
export default {
  name: "elementButton",
  props : {
    value : {
      type : Object,
      default() { return {} }
    }
  },
  data () {
    return {
      headerStyle : null
    }
  },
  watch : {
    'value.color'(value) {
      this.headerStyle.style.color = value
    },
  },
  methods : {
    getButtonStyle() {
      let style = '';
      if ( this.value.background ) {
        style += `background : ${this.value.background}; `
      }
      if ( this.value.borderRadius ) {
        style += `border-radius : ${this.value.borderRadius}px; `
      }
      if ( this.value.width ) {
        style += `width :  ${this.value.width}px; `
      }

      if ( this.value.paddingTop ) {
        style += `padding-top: ${this.value.paddingTop}px;`
      }
      if ( this.value.paddingBottom ) {
        style += `padding-bottom: ${this.value.paddingBottom}px;`
      }
      if ( this.value.paddingLeft ) {
        style += `padding-left: ${this.value.paddingLeft}px;`
      }
      if ( this.value.paddingRight ) {
        style += `padding-right: ${this.value.paddingRight}px;`
      }

      if ( this.value.marginTop ) {
        style += `margin-top: ${this.value.marginTop}px;`
      }
      if ( this.value.marginBottom ) {
        style += `margin-bottom: ${this.value.marginBottom}px;`
      }
      if ( this.value.marginLeft ) {
        style += `margin-left: ${this.value.marginLeft}px;`
      }
      if ( this.value.marginRight ) {
        style += `margin-right: ${this.value.marginRight}px;`
      }

      return style
    },
    getAlignClass() {
      switch (this.value.align) {
        case 'left'    : return ''
        case 'center'  : return 'justify-center'
        case 'right'   : return 'justify-end'
        default : return ''
      }
    },
    getStyle() {
      let style = '';

      switch (this.value.weight) {
        case 'light'  : style += 'font-weight: 300 ; '    ;  break;
        case 'medium' : style += 'font-weight: 500 ; '  ;  break;
        case 'bold'   : style += 'font-weight: 700 ; '    ;  break;
        default :  style +=      'font-weight: 500 ; '
      }

      switch (this.value.size) {
        case 'h1' : style += 'font-size: 2em    ;'    ;  break;
        case 'h2' : style += 'font-size: 1.5em  ;'  ;  break;
        case 'h3' : style += 'font-size: 1em    ;'    ;  break;
        case 'h4' : style += 'font-size: 0.92em ;' ;  break;
        case 'h5' : style += 'font-size: 0.83em ;' ;  break;
        case 'h6' : style += 'font-size: 0.67em ;' ;  break;
        default :  style += 'font-size: 2em ;'
      }

      style += 'line-height : 1; '

      return style
    },
    editElement() {
      this.$emit('select')
    },
  },
  mounted() {
    let el = this.$refs.heading.$el
    this.headerStyle = el.querySelector('textarea');
    this.headerStyle.style.color = this.value.color
    this.headerStyle.style.textAlign = 'center'
  }
}
</script>

<style scoped>

</style>