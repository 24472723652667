<template>
  <v-hover #default="{hover}">
    <div
        v-click-outside="deselect"
        style="position: relative">
      <!-- Element Settings Sidebar  -->
      <v-expand-x-transition>
        <element-edit-dialog
            @close="displaySettings = false "
            v-if="displaySettings"
            v-model="displaySettings"
            :element="value"
            @update="updateElement"
        />
      </v-expand-x-transition>

      <!-- Drag Handle  -->
      <!-- Element Edit Panel  -->
      <v-sheet
          color="transparent"
          v-if="displaySettingsCondition(hover)"
          class="  d-flex  pb-1"
          :class="[{'mt-n10' : true}]"
          style="position:absolute;"
      >
        <v-sheet
            :style="`border : 1px solid ${wsBACKGROUND}`"
            class="wsRoundedLight d-flex align-center"
            style="z-index: 250"
        >

          <v-icon @click="edit = false" class="element_handle" style="cursor : grab ">mdi-drag</v-icon>

          <!-- Button Edit Buttons  -->
          <template v-if="buttonCondition || value.type === 'text' ">
            <!-- Size  -->
            <ft-select v-model="value.size"
                       :items="sizeSelect"
                       @expand="edit = $event"
            >
              <v-btn :color="wsACCENT" icon >
                <v-icon v-if="headingCondition || value.type === 'button'">{{ sizeIcon }}</v-icon>
                <h5 v-else> {{ value.size }}</h5>
              </v-btn>
            </ft-select>
            <!-- Weight  -->
            <ft-select v-model="value.weight"
                       :items="weightSelect"
                       @expand="edit = $event"
            >
              <v-btn  :color="wsACCENT" icon >
                <v-icon >{{ weightIcon }}</v-icon>
              </v-btn>
            </ft-select>
            <!-- Text Color   -->
            <v-menu v-model="backgroundDropdown"   key="color_picker_menu"  :close-on-content-click="false" offset-y>
              <template v-slot:activator="{attrs,on}">
                <v-btn class="px-0 mx-0" :color="value.color" v-bind="attrs" v-on="on" text min-width="35" width="35">
                  <v-icon>mdi-format-text-variant-outline</v-icon>
                </v-btn>
              </template>
              <v-sheet  class="pa-2 salvation-toolbar">
                <a-color-picker
                    key="color_picker"
                    :color-set="$store.state.newsletter.colorSet"
                    v-model="value.color"
                />
              </v-sheet>
            </v-menu>

            <v-menu key="background_color_picker_menu" v-model="colorDropdown" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{attrs,on}">
                <v-btn class="px-0 mx-0" :color="value.background" v-bind="attrs" v-on="on" text min-width="35" width="35">
                  <v-icon>mdi-circle</v-icon>
                </v-btn>
              </template>
              <v-sheet  class="pa-2 salvation-toolbar">
                <a-color-picker
                    key="background_color_picker"
                    :color-set="$store.state.newsletter.colorSet"
                    v-model="value.background"
                />
              </v-sheet>
            </v-menu>

            <v-divider  :style="`border-color : ${wsBACKGROUND}`" vertical/>

          </template>

          <!-- Text Edit Buttons  -->
          <template v-if="headingCondition">
            <!-- Size  -->
            <ft-select v-model="value.size"
                       :items="sizeSelect"
                       @expand="edit = $event"
            >
              <v-btn :color="wsACCENT" icon >
                <v-icon v-if="headingCondition">{{ sizeIcon }}</v-icon>
                <h5 v-else> {{ value.size }}</h5>
              </v-btn>
            </ft-select>
            <!-- Weight  -->
            <ft-select v-model="value.weight"
                       :items="weightSelect"
                       @expand="edit = $event"
            >
              <v-btn  :color="wsACCENT" icon >
                <v-icon>{{ weightIcon }}</v-icon>
              </v-btn>
            </ft-select>
            <!-- Align  -->
            <ft-select v-model="value.align"
                       :items="alignSelect"
                       @expand="edit = $event"
            >
              <v-btn  :color="wsACCENT" icon >
                <v-icon>mdi-format-align-{{ value.align || 'left' }}</v-icon>
              </v-btn>
            </ft-select>
            <!-- Color  -->
            <v-menu v-model="colorDropdown" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{attrs,on}">
                <v-btn class="px-0 mx-0" :color="value.color" v-bind="attrs" v-on="on" text min-width="35" width="35">
                  <v-icon>mdi-circle</v-icon>
                </v-btn>
              </template>
              <v-sheet  class="pa-2 salvation-toolbar">
                <a-color-picker
                    :color-set="$store.state.newsletter.colorSet"
                    v-model="value.color"
                />
              </v-sheet>
            </v-menu>
            <v-divider  :style="`border-color : ${wsBACKGROUND}`" vertical/>
          </template>



          <v-btn @click="displaySettings = true" :color="wsACCENT" icon >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="$emit('copy')" :color="wsACCENT" icon >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
          <v-btn @click="$emit('delete')"  :color="wsACCENT" icon >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-sheet>
      </v-sheet>
      <!-- Element Content-->
      <v-sheet color="transparent" >
        <element-heading
            v-if="$store.state.newsletter.headingTypesArray.includes(value.type) || value.type === 'heading' "
            :value="value"
            @focus="edit = true"
        />
        <element-text
            v-if="value.type ===  'text'  "
            @focus="edit = true"
            :value="value"
        />
        <element-image
            v-if="value.type ===  'image'  "
            @click.stop=""
            :admin="admin"
            :value="value"
        />
        <element-button
            v-if="$store.state.newsletter.buttonTypesArray.includes(value.type) || value.type === 'button' "
            :value="value"
            @click.stop=""
        />
      </v-sheet >

    </div>
  </v-hover>
</template>

<script>

import elementEditDialog
  from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/dialogs/elementEditDialog";
import elementHeading
  from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/editor/elementHeading";
import elementText from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/editor/elementText";
import elementImage
  from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/editor/elementImage";
import elementButton
  from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/editor/elementButton";

export default {
  name: "editorElement",
  components : {
    elementEditDialog,
    elementHeading,
    elementText,
    elementImage,
    elementButton,
  },
  props : {
    value : {
      type : Object ,
      default() { return {} }
    },
    admin : {
      type : Boolean,
      default : false
    },
    index : {
      type : Number,
    },
    isLast: {
      type : Boolean,
      default  : false
    },
    disableEdit : {
      type : Boolean,
      default  : false
    }
  },
  data() {
    return {
      edit : false,
      displaySettings : false,
      colorDropdown : false,
      backgroundDropdown : false
    }
  },
  computed : {
    headingCondition() {
      return this.value.type === 'heading' || this.$store.state.newsletter.headingTypesArray.includes(this.value.type)
    },
    buttonCondition() {
      return this.value.type === 'button' || this.$store.state.newsletter.buttonTypesArray.includes(this.value.type)
    },
    sizeIcon() {
      if ( this.headingCondition || this.value.type === 'button' ) {
        if ( !this.value.size ) {
          return 'mdi-format-size'
        }
        return 'mdi-format-header-' + this.value.size.slice(1,2)
      }
   return this.value.size
    },
    weightIcon() {
      switch ( this.value.weight ) {
        case 'light' : return 'mdi-format-text-variant'
        case 'bold' : return 'mdi-format-bold'
        default : return 'mdi-format-font'
      }
    },
    sizeSelect() {
      if ( this.headingCondition || this.value.type === 'button'  ) {
        return [
          { text : 'H1' , value : 'h1' },
          { text : 'H2' , value : 'h2' },
          { text : 'H3' , value : 'h3' },
          { text : 'H4' , value : 'h4' },
          { text : 'H5' , value : 'h5' },
          { text : 'H6' , value : 'h6' },
        ]
      }
      return [
        { text : 10 , value : 10 },
        { text : 12 , value : 12 },
        { text : 16 , value : 16 },
        { text : 18 , value : 18 },
        { text : 20 , value : 20 },
        { text : 22 , value : 22 },
      ]

    },
    alignSelect() {
      return [
        { text : this.$t('AlignLeft')   , value : 'left' },
        { text : this.$t('AlignCenter') , value : 'center' },
        { text : this.$t('AlignRight')  , value : 'right' },
      ]
    },
    weightSelect() {
      return [
        { text : this.$t('FontWeightLight')  , value : 'light' },
        { text : this.$t('FontWeightMedium') , value : 'medium' },
        { text : this.$t('FontWeightBold')   , value : 'bold' },
      ]
    },
    blockItemsSelect() {
      return [
        { text: this.$t('ElementHeading') , icon: 'mdi-format-header-1'    , value: 'heading'},
        { text: this.$t('ElementText')    , icon: 'mdi-text'               , value: 'text'},
        { text: this.$t('ElementImage')   , icon: 'mdi-image'              , value: 'image'},
        { text: this.$t('ElementButton')  , icon: 'mdi-gesture-tap-button' , value: 'button'}
      ]
    },
  },
  methods : {
    updateElement($event) {
      this.notify($event)
      this.value = JSON.parse(JSON.stringify($event))
       this.$emit('input' ,JSON.parse(JSON.stringify($event))   )
    },
    deselect() {
      if ( (!this.colorDropdown && !this.backgroundDropdown ) && !this.displaySettings ) {
        this.edit = false
      }
    },
    textFocus(value) {
      this.notify(`text has been ${value ?  'focused' : 'unfocused'} `)
      this.edit = value
    },
    displaySettingsCondition(hover) {
      if ( this.disableEdit ) {
        return false
      }
      if ( ['heading' , 'text'].includes(this.value.type) ) {
        return this.edit
      }
      return hover || this.edit || this.colorDropdown || this.backgroundDropdown
    },
  }
}
</script>

<style scoped>

</style>