<template>
  <sub-page
      no-page-info
      style="height: 100%"
  >

    <template #default>

      <v-sheet style="position:fixed; z-index: 1" width="100%" dark class="py-1 px-4 " :color="wsACCENT">
        <div class="d-flex align-center justify-space-between">
          <v-btn @click="$emit('close')" text class="noCaps">
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t('Back') }}
          </v-btn>
          <div class="d-flex">
            <v-btn @click="displaySettings = true" text class="noCaps">{{ $t('Settings') }}</v-btn>
            <v-btn @click="saveContent" outlined class="noCaps">{{ $t('Save') }}</v-btn>
          </div>

        </div>
      </v-sheet>
      <!-- Email Body -->
      <v-sheet :color="newsLetterParams.backgroundColor" style="padding-top: 44px" height="100%" class="d-flex justify-center fill-height ">
        <!-- Blocks-->
        <v-sheet width="700" min-width="400" max-width="700" color="transparent" >
          <v-sheet class=" wsRounded " :color="wsBACKGROUND">

            <v-sheet id="content"  :color="newsLetterParams.backgroundColor" style="position: relative;">

                <template  v-for="(row,i) in emailBody ">
                    <block-row
                        :key="i + 'row'"
                        :value="row"
                        :index="i"
                        :system-email="systemEmail"
                        :is-last="i === (emailBody.length -1)"
                        @delete="displayDelete(row)"
                        @deleteElement="displayDeleteElement(i , $event )"
                        @copy="duplicateBlock(row)"
                        @copyElement="copyElement(i , $event )"
                        @move="moveBlock(row, i + $event)"
                        @addBlock="addBlock('block_row', i + $event )"
                        :admin="admin"
                    />
                </template>

              <div class="d-flex justify-center py-3"  >
                <v-sheet :color="newsLetterParams.backgroundColor"
                         class="d-flex justify-space-between" >
                  <v-btn @click="addBlock('block_row' , emailBody.length)"
                         outlined
                         width="270"
                         :color="wsACCENT"
                         class="noCaps px-16" elevation="0"   >
                    {{ $t('AddBlock') }}
                  </v-btn>
                </v-sheet>
              </div>

              <v-sheet :color="wsBACKGROUND" >
                <v-sheet :color="newsLetterParams.backgroundColor" class="pt-3">
                  <h5 class="font-weight-medium text-center">  {{ $store.state.business.selectedBusiness.name }} </h5>
                  <h5 class="font-weight-light text-center mt-3"> {{ $t('Phone') }}: {{newsLetterParams.phone ? newsLetterParams.phone : $t('EnterPhone') }}</h5>
                  <h5 class="font-weight-light text-center "> {{ $t('Email') }}: {{newsLetterParams.email ? newsLetterParams.email : $t('EnterEmail')}}</h5>
                  <h5 v-if="newsLetterParams.address" class="font-weight-light text-center"> {{ $t('Address') }}: {{newsLetterParams.address  ? newsLetterParams.address : $t('EnterAddress')}}</h5>
                  <h6 class="font-weight-light text-center mt-3"> {{ $t('EmailSubscriptionText') }}  <span class="font-weight-medium">  {{ $store.state.business.selectedBusiness.name }}</span></h6>
                  <h6 class="font-weight-light text-center "> {{ $t('EmailUnsubscribeText') }}</h6>
                  <h5 class="font-weight-light text-center mt-3">{{ (new Date()).getFullYear() }} - {{ $t('AllRightsReserved') }}</h5>
                </v-sheet>
              </v-sheet>


              <v-sheet :color="wsBACKGROUND" class="pr-9 fill-height">
                <v-sheet class="fill-height" :color="newsLetterParams.backgroundColor">
                </v-sheet>
              </v-sheet>

            </v-sheet>

          </v-sheet>
        </v-sheet>
      </v-sheet>
    </template>
    <template #dialog>
      <v-expand-x-transition>
        <email-edit-dialog
            @close="displaySettings = false "
            v-if="displaySettings"
            v-model="displaySettings"
            :block="newsLetterParams"
        />
      </v-expand-x-transition>
      <wsDialog v-model="displayConfirmDeleteBlock"
                :title="$t('avalon.block.delete')"
                display-confirm-delete
                close-delete
                @save="deleteBlock"
                @delete="deleteBlock" >
      </wsDialog>
      <wsDialog v-model="displayConfirmDeleteElement"
                :title="$t('avalon.element.delete')"
                close-delete
                display-confirm-delete
                @delete="deleteElement" >
      </wsDialog>
    </template>

  </sub-page>
</template>

<script>
import emailEditDialog
  from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/dialogs/emailEditDialog";
import blockRow from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/editor/editorBlock";
import {mapActions} from "vuex";
export default {
  name: "newsletterDesign",
  props : {
    uuid : {
      type : String,
      default : ''
    },
    newsletterId : {
      type : String,
      default : ''
    },
    systemEmail : {
      type : String,
      default : ''
    },
    name : {
      type : String,
      default : ''
    },
    admin : {
      type : Boolean,
      default : false
    },
    template : {
      type : Boolean,
      default : false
    }
  },
  components : {
    // Editor,
    emailEditDialog,
    blockRow,
  },
  data() {
    return {
      displaySettings : false ,

      navigation : 'email',
      emailBody : [],
      content : '',
      selectedBlock : {},
      selectedElement : {},
      newsLetterParams : {
        backgroundColor : '#ffffff',
        phone : null,
        email : null,
        address : null
      },
      selectedBlockIndex : null,
      selectedElementColumn : 0,
      selectedElementIndex : 0,

      displayConfirmDeleteElement : false,
      displayConfirmDeleteBlock   : false
    }
  },
  computed : {
    defaultBlock() {
      return {
        row : {
          type : 'block_row',
          name : this.$t('BockRow'),
          backgroundColor : "#FFFFFF00",
          rows : 1,
          elements : [[]],
          uuid : "",
          paddingTop : 25,
          paddingBottom : 25,
          paddingLeft : 25,
          paddingRight : 25,
          marginTop : 0,
          marginBottom : 0,
          marginLeft : 0,
          marginRight : 0,
          borderRadius : 5,
        }
      }
    },
    editorOptions() {
      return {
        minHeight : 500,
        width : '100%',
        plugins: 'powerpaste',
        menubar : false,
        toolbar: true,
        inline: true,
        force_br_newlines : true,
        forced_root_block : false,
        statusbar: false,
        placeholder: this.$t('EnterText'),
        powerpaste_word_import: 'clean',
        powerpaste_html_import: 'clean',
        // skin: 'naked',
        setup: this.editorSetup,
        browser_spellcheck: true,
        // content_style: 'div { font  }'
        // skins : naked document outside
      }
    },
  },
  methods : {
    ...mapActions('newsletter', [
      'SAVE_NEWSLETTER_DESIGN',
      'GET_NEWSLETTER_DESIGN',
      'SAVE_ADMIN_NEWSLETTER_DESIGN',
      'GET_ADMIN_NEWSLETTER_DESIGN',
      'GET_DEFAULT_EMAIL_DESIGN',
      'GET_ADMIN_SYSTEM_EMAIL_DESIGN',
      'GET_DEFAULT_SETTINGS'
    ]),

    displayDelete(block) {
      this.selectedBlock = block
      this.displayConfirmDeleteBlock = true
    },
    displayDeleteElement(blockIndex , { column , index }) {
      this.selectedBlockIndex = blockIndex
      this.selectedElementColumn = column
      this.selectedElementIndex = index
      this.displayConfirmDeleteElement = true
    },

    unselect() {
      this.selectedBlock = {}
      this.selectedElement = {}
    },
    selectBlock(row) {
      this.navigation = 'block'
      this.selectedBlock = row
      this.selectedElement = {}
    },
    selectElement(data,row) {
      this.navigation = 'element'
      this.selectedElement = data.element;
      this.selectedElementColumn = data.column
      this.selectedElementIndex = data.index
      this.selectedBlock = row;
    },
    addBlock(type , position) {
      switch(type) {
        case 'block_row' :
          var block = JSON.parse(JSON.stringify(this.defaultBlock.row));
          block.uuid = this.$uuid.v4()
          this.emailBody.splice(position , 0 , block)
          break;
      }
    },
    deleteElement() {
      this.selectedElement = {}
      this.emailBody[this.selectedBlockIndex].elements[this.selectedElementColumn].splice(this.selectedElementIndex,1)
      this.displayConfirmDeleteElement = false
    },
    copyElement(blockIndex , { element , column , position  } ) {

      this.selectedElement = {}
      let newElement = JSON.parse(JSON.stringify(element))
      newElement.uuid = this.$uuid.v4()
      this.emailBody[blockIndex].elements[column].splice(position,0 ,newElement  )
    },
    deleteBlock() {
      let index = this.emailBody.findIndex(el => el.uuid === this.selectedBlock.uuid)
      if ( index !== -1 ) {
        this.selectedBlock = {}
        this.emailBody.splice(index,1)
        this.displayConfirmDeleteBlock = false
      }
    },
    moveBlock(block , position ) {
      let index = this.emailBody.findIndex(el => el.uuid === block.uuid)
      if ( index !== -1 ) {
        this.emailBody.splice(index,1)
        this.emailBody.splice(position , 0, block)
        this.displayConfirmDeleteBlock = false
      }
    },
    duplicateBlock(selectedBlock) {
      let index = this.emailBody.findIndex(el => el.uuid === selectedBlock.uuid)
      if ( index !== -1 ) {
        let block = JSON.parse(JSON.stringify(selectedBlock))
        block.uuid = this.$uuid.v4()
        this.emailBody.splice(index,0,block)
        this.displayConfirmDeleteBlock = false
      }
    },
    async saveContent() {

      let data = {
        newsletter_id : this.newsletterId,
        params : this.newsLetterParams,
        body : this.emailBody
      }
      if ( this.uuid ) {
        data.uuid = this.uuid
      }
      if ( this.newsletterId ) {
        data.newsletter_id = this.newsletterId
      }
      if (this.systemEmail) {
        data.system_email = this.systemEmail
      }
      if ( this.is_template) {
        data.is_template = this.is_template
      }

      let result = !this.admin ? await this.SAVE_NEWSLETTER_DESIGN(data) : await this.SAVE_ADMIN_NEWSLETTER_DESIGN(data)

      if ( !result ) {
        return
      }
      if ( !this.uuid ) {
        this.$emit('updateId' , result.uuid )
      }
      this.$emit('save' , result.uuid)
      this.notify(this.$t('ChangesSaved'))


    },

    async checkContacts() {
      let result = await this.GET_DEFAULT_SETTINGS()
      if ( !result || result === true ) {
        return
      }
      if ( !this.newsLetterParams.phone) {
        this.newsLetterParams.phone = result.phone
      }
      if ( !this.newsLetterParams.email) {
        this.newsLetterParams.email = result.email
      }
      if ( !this.newsLetterParams.address) {
        this.newsLetterParams.address = result.address
      }
    }
  },
  async mounted() {
    this.$store.state.newsletter.emailDesignSaveFunction = this.saveContent
    this.$store.state.newsletter.emailDesignEditFunction = () => { this.displaySettings = true }
    let result = null

    if (this.uuid) {
      result = !this.admin ?  await this.GET_NEWSLETTER_DESIGN(this.uuid) : await this.GET_ADMIN_NEWSLETTER_DESIGN(this.uuid)
    } else if (this.systemEmail) {
      result = !this.admin ?  await this.GET_DEFAULT_EMAIL_DESIGN(this.systemEmail) : await this.GET_ADMIN_SYSTEM_EMAIL_DESIGN(this.systemEmail)
    }

    if (!this.uuid && result && result.uuid ) {
      this.$emit('updateId' , result.uuid )
    }


    if ( !result || result === true ) {
      this.checkContacts()
      return
    }
    this.emailBody = result.body || []
    this.newsLetterParams = result.params || {}

    this.checkContacts()

  },
  beforeDestroy() {
    this.$store.state.newsletter.emailDesignSaveFunction = null
    this.$store.state.newsletter.emailDesignEditFunction = null
  }
}
</script>

<style scoped>
.singleLineEditor {
  padding: 25px;
}
.singleLineEditor *[contentEditable="true"]:focus,
.singleLineEditor *[contentEditable="true"]:hover {
  outline: none;
}
</style>